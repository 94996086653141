import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

function TasksTimeline({ tasks }) {
  const [view, setView] = useState('week');

  // Convert tasks to events format expected by react-big-calendar
  const events = tasks.map((task, index) => ({
    id: task.id || index + 1,
    title: task.content,
    start: new Date(task.start),
    end: new Date(task.end),
    status: getStatusFromGroup(task.group)
  }));

  // Function to convert group number to status string
  function getStatusFromGroup(groupId) {
    switch(groupId) {
      case 1: return 'TODO';
      case 2: return 'Doing';
      case 3: return 'On Hold';
      case 4: return 'Done';
      default: return 'Unknown';
    }
  }

// 
// [
//   {
//     id: number,
//     content: string,
//     start: Date or date string,
//     end: Date or date string,
//     group: number (1-4)
//   },
//    ... more tasks
// ]
// 
  // Define colors for each status
  const statusColors = {
    'TODO': '#FF5733',
    'Doing': '#5DADE2',
    'On Hold': '#F4D03F',
    'Done': '#58D68D',
    'Unknown': '#808080'
  };

  // Custom event style
  const eventStyleGetter = (event) => {
    const style = {
      backgroundColor: statusColors[event.status],
      borderRadius: '5px',
      opacity: 0.8,
      color: 'white',
      border: '0px',
      display: 'block'
    };
    return {
      style: style
    };
  };

  return (
    <div style={{ height: '70vh' }}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{ height: '100%' }}
        view={view}
        onView={setView}
        views={['week', 'day']}
        eventPropGetter={eventStyleGetter}
      />
    </div>
  );
}

export default TasksTimeline;