import React, { useState, useEffect } from 'react';
import { saveContact } from '../../api/contacts'; // Ensure this path is correct
import { useTranslation } from 'react-i18next';

function ContactFormModal({ contacts, setContacts, showAddContactModal, setShowAddContactModal, contact, onSave }) {
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [title, setTitle] = useState('');
    const [city, setCity] = useState('');
    const [status, setStatus] = useState('');
    const [interested, setInterested] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [organization, setOrganization] = useState('');
    const [LeadSource, setLeadSource] = useState([]);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        if (contact) {
            setName(contact.name || '');
            setEmail(contact.email || '');
            setTitle(contact.title || '');
            setCity(contact.city || '');
            setStatus(contact.status || '');
            setInterested(contact.interested_in || '');
            setAddress(contact.address || '');
            setPhone(contact.phone || '');
            setLeadSource(contact.LeadSource || '');
            setOrganization(contact.organization || '');
        } else {
            setName('');
            setLeadSource('');
            setEmail('');
            setTitle('');
            setCity('');
            setStatus('');
            setInterested('');
            setAddress('');
            setPhone('');
            setOrganization('');
        }
    }, [contact]);

    const handleCloseAddContactModal = () => {
        setShowAddContactModal(false);
    };

    const handleSubmit = async () => {
        const newContact = {
            name,
            email,
            title,
            city,
            address,
            LeadSource,
            phone,
            organization,
            status,
            interested_in: interested
        };

        try {
            const savedContact = await saveContact(newContact);
            onSave([savedContact, ...contacts]);
            setShowAddContactModal(false);
        } catch (error) {
            setErrors(error.message);
        }
    };

    return (
        <div className={`modal fade ${showAddContactModal ? 'show d-block' : ''}`} tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: 800, padding: '1.7rem' }}>
                <div className="modal-content py-3 px-4 border-0 shadow-lg" style={{ maxHeight: 800, overflow: 'auto' }}>
                    <div className="modal-header pb-0 border-0 d-flex align-items-center">
                        <div>
                            <h4 className="modal-title bold m-0">{t('Add Contact')}</h4>
                        </div>
                        <span type="button" className="close ms-auto m-0 text-secondary" onClick={handleCloseAddContactModal} style={{ fontSize: '25pt', fontWeight: '300' }}>
                            <span aria-hidden="true">&times;</span>
                        </span>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-6 p-2">
                                <input type="text" className="form-control" placeholder={t('Name')} value={name} onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div className="col-md-6 p-2">
                                <input type="email" className="form-control" placeholder={t('Email')} value={email} onChange={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="col-md-6 p-2">
                                <input type="text" className="form-control" placeholder={t('Title')} value={title} onChange={(e) => setTitle(e.target.value)} />
                            </div>
                            <div className="col-md-6 p-2">
                                <input type="text" className="form-control" placeholder={t('City')} value={city} onChange={(e) => setCity(e.target.value)} />
                            </div>
                            <div className="col-md-6 p-2">
                                <select className="form-control" value={status} onChange={(e) => setStatus(e.target.value)}>
                                    <option value="">סטטוס ליד</option>
                                    <option value="לא ענה">לא ענה</option>
                                    <option value="תואם ייעוץ">תואם ייעוץ</option>
                                    <option value="תואם טיפול">תואם טיפול</option>
                                    <option value="להתקשר מאוחר יותר">להתקשר מאוחר יותר</option>
                                    <option value="להתקשר עוד שבוע">להתקשר עוד שבוע</option>
                                    <option value="לא רלוונטי">לא רלוונטי</option>
                                </select>
                            </div>
                            <div className='col-md-6 p-2'>
                                    <select className='form-control' value={LeadSource} onChange={(e) => setLeadSource(e.target.value)}>
                                        <option value="">מקור הליד</option>
                                        <option value="ידני">ידני</option>
                                        <option value=""> אינטגרם/ פייסבוק</option>
                                        <option value="אתר">אתר</option>
                                        <option value="גוגל">גוגל</option>
                                        <option value="אחר">אחר</option>
                                    </select>
                                </div>
                            <div className="col-md-6 p-2">
                                <select className="form-control" value={interested} onChange={(e) => setInterested(e.target.value)}>
                                    <option value="">מעוניין ב...</option>
                                    <option value="תספורת">תספורת</option>
                                    <option value="תסרוקת">תסרוקת</option>
                                    <option value="החלקה">החלקה</option>
                                    <option value="תוספות שיער">תוספות שיער</option>
                                    <option value="צבע">צבע</option>
                                    <option value="תספורת גברים">תספורת גברים</option>
                                    <option value="החלקה חוזרת">החלקה חוזרת</option>
                                    <option value="מוצרים נלווים">מוצרים נלווים</option>
                                    <option value="כללי">כללי</option>
                                    <option value="פן">פן</option>
                                    <option value="מועדון לקוחות">מועדון לקוחות</option>
                                </select>
                            </div>
                            <div className="col-md-6 p-2">
                                <input type="text" className="form-control" placeholder="כתובת" value={address} onChange={(e) => setAddress(e.target.value)} />
                            </div>
                            <div className="col-md-6 p-2">
                                <input type="text" className="form-control" placeholder={t('Phone')} value={phone} onChange={(e) => setPhone(e.target.value)} />
                            </div>
                            <div className="col-md-12 p-2">
                                <input type="text" className="form-control" placeholder={t('Organization')} value={organization} onChange={(e) => setOrganization(e.target.value)} />
                            </div>
                            {errors && (
                                <div className="text-danger small">
                                    {errors.map((errorMessage, index) => (
                                        <span key={index}>{errorMessage}</span>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="modal-footer border-0">
                        <button className="btn btn-primary rounded" onClick={handleSubmit}>{t('Save')}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactFormModal;
