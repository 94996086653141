import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { getContacts, deleteContact } from '../api/contacts';
import ContactFormModal from '../components/contacts/ContactFormModal'; // Assuming you renamed AddContactModal to ContactFormModal
import { useTranslation } from 'react-i18next';

function Contacts({ contacts, setContacts, leads, setLeads }) {
    const { t } = useTranslation();
    const [filteredContacts, setFilteredContacts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedContact, setSelectedContact] = useState(null);
    const [showAddContactModal, setShowAddContactModal] = useState(false);
    const [showEditContactModal, setShowEditContactModal] = useState(false);

    useEffect(() => {
        const fetchContacts = async () => {
            try {
                const fetchedContacts = await getContacts();
                setContacts(fetchedContacts);
                setFilteredContacts(fetchedContacts);
            } catch (error) {
                console.error('Error fetching contacts:', error);
            }
        };

        fetchContacts();
    }, [setContacts]);

    useEffect(() => {
        const results = contacts.filter(contact =>
            Object.values(contact).some(
                value => value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
        setFilteredContacts(results);
    }, [searchTerm, contacts]);

    const openAddContactModal = () => {
        setShowAddContactModal(true);
    };

    const handleDeleteContact = async (contact_id) => {
        try {
            await deleteContact(contact_id);
            const updatedContacts = contacts.filter(contact => contact.id !== contact_id);
            setContacts(updatedContacts);
        } catch (error) {
            console.error('Error deleting contact:', error);
        }
    };

    const openEditContactModal = (contact) => {
        setSelectedContact(contact);
        setShowEditContactModal(true);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSaveContact = (updatedContacts) => {
        setContacts(updatedContacts);
        setShowAddContactModal(false);
        setShowEditContactModal(false);
    };

    return (
        <div className="container">
            <div className="d-flex justify-content-center pt-3 pb-4">
                <button className="btn btn-basic bg-white shadow-sm medium" onClick={openAddContactModal}>
                    <span className="text-primary">
                        <FontAwesomeIcon icon={faPlus} className="pe-1" /> הוספת איש קשר חדש
                    </span>
                </button>
            </div>
            <input
                type="text"
                placeholder="חיפוש ברשימה"
                value={searchTerm}
                onChange={handleSearch}
                className="form-control my-3 border border-primary"
            />
            <div className="m-auto d-block w-100" style={{ maxWidth: 1500, overflowX: 'auto' }}>
                <div className="table-responsive pt-3">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th className="text-secondary bg-transparent fw-500">שם</th>
                                <th className="text-secondary bg-transparent fw-500">אימייל</th>
                                <th className="text-secondary bg-transparent fw-500">תפקיד</th>
                                <th className="text-secondary bg-transparent fw-500">סטטוס</th>
                                <th className="text-secondary bg-transparent fw-500">מעוניין ב...</th>
                                <th className="text-secondary bg-transparent fw-500">עיר</th>
                                <th className="text-secondary bg-transparent fw-500">כתובת</th>
                                <th className="text-secondary bg-transparent fw-500">טלפון</th>
                                <th className="text-secondary bg-transparent fw-500">חברה</th>
                                <th className="text-center text-secondary bg-transparent fw-500">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredContacts.map(contact => (
                                <tr key={contact.id}>
                                    <td className="bg-transparent">{contact.name}</td>
                                    <td className="bg-transparent">{contact.email}</td>
                                    <td className="bg-transparent">{contact.title}</td>
                                    <td className="bg-transparent">{contact.status}</td>
                                    <td className="bg-transparent">{contact.interested_in}</td>
                                    <td className="bg-transparent">{contact.city}</td>
                                    <td className="bg-transparent">{contact.address}</td>
                                    <td className="bg-transparent">{contact.phone}</td>
                                    <td className="bg-transparent">{contact.organization}</td>
                                    <td className="bg-transparent">
                                        <div className="h-100 d-flex align-items-center justify-content-center">
                                            <div className="px-1">
                                                <button className="btn btn-basic" onClick={() => openEditContactModal(contact)}>
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </button>
                                            </div>
                                            <div className="px-1">
                                                <button className="btn btn-basic text-danger" onClick={() => handleDeleteContact(contact.id)}>
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <ContactFormModal
                contacts={contacts}
                setContacts={setContacts}
                showAddContactModal={showAddContactModal}
                setShowAddContactModal={setShowAddContactModal}
                contact={selectedContact}
                onSave={handleSaveContact}
            />

            <ContactFormModal
                contacts={contacts}
                setContacts={setContacts}
                showAddContactModal={showEditContactModal}
                setShowAddContactModal={setShowEditContactModal}
                contact={selectedContact}
                onSave={handleSaveContact}
            />
        </div>
    );
}

export default Contacts;
